import {
  API_ENDPOINT,
  API_GATEWAY_ENDPOINT,
  CLOUD_FRONT_ABSOLUTE_ENDPOINT,
  CODEBUILD_BUILD_NUMBER,
  CODEBUILD_GIT_SHORT_ID,
  LOCAL_ONLY_DEVELOPMENT,
} from "./api-config.mjs";

// https://github.com/JustinBeckwith/retry-axios
import * as rax from "retry-axios";
import axios from "axios";
import { addDays, subDays, startOfDay, endOfDay } from "date-fns";

// https://daveceddia.com/multiple-environments-with-react/

import log from "loglevel";
import { getSymbol } from "./components/Symbols.mjs";

rax.attach();

async function doGet(url = "") {
  const response = await fetch(url);
  if (!response.ok) {
    let error = {
      status: response.status,
      statusText: response.statusText,
    };
    error.responseBody = await response.text();
    log.warn("doGet()", error);
    throw error;
  }
  return await response.json();
}

export async function connectWebSocket(url, options = {}) {
  const {
    onOpen = () => {},
    onMessage = () => {},
    onError = () => {},
    onClose = () => {},
    protocols = []
  } = options;

  const ws = new WebSocket(url, protocols);

  ws.onmessage = (event) => {
    log.debug('WebSocket message received:', event.data);
    onMessage(event);
  };

  ws.onerror = (event) => {
    log.error('WebSocket error:', event);
    onError(event);
  };

  ws.onclose = (event) => {
    log.debug('WebSocket closed');
    onClose(event);
  };

  // Wait for connection to open
  await new Promise((resolve, reject) => {
    ws.onopen = (event) => {
      log.debug('WebSocket connected');
      onOpen(event);
      resolve();
    };
    ws.onerror = (event) => {
      reject(event);
    };
  });

  return ws;
}

export async function sendWebSocketMessage(ws, message) {
  if (ws.readyState === WebSocket.OPEN) {
    try {
      ws.send(typeof message === 'string' ? message : JSON.stringify(message));
    } catch (error) {
      log.error('Error sending WebSocket message:', error);
      throw error;
    }
  } else {
    throw new Error('WebSocket is not open');
  }
}


export async function fetchEarnings(
  last_evaluated_key = null,
  event_id = null,
  filter_condition = null
) {
  let queryParams = "";
  if (last_evaluated_key) {
    queryParams = `last_evaluated_key=${last_evaluated_key}`;
  }
  if (event_id) {
    queryParams = `event_id=${event_id}`;
  }
  if (filter_condition) {
    if (!!queryParams) {
      queryParams += `&${filter_condition}`;
    } else {
      queryParams = filter_condition;
    }
  }

  const url = `${API_ENDPOINT}/earnings?${queryParams}`;
  log.debug("loading url", url);
  const response = await fetch(url);
  if (!response.ok) {
    log.warn("Response Not OK:", response);
    throw response;
  }
  return response.json();
}

function authHeaderPair(xAuthType, xAuthToken) {
  return {
    "x-auth-type": xAuthType,
    "x-auth-token": xAuthToken,
  };
}

export function extractAuthHeaders({ context }) {
  const { sessionContext } = context || {};
  const {
    session,
    google,
    facebook,
    test,
    appBuilderKey,
    customAuth,
    loginWithEmail,
  } = sessionContext || {};

  if (session) {
    return authHeaderPair("session", session.session_id);
  } else if (google) {
    return authHeaderPair("google-web-code", google.code);
  } else if (facebook) {
    return authHeaderPair("facebook-web", facebook.accessToken);
  } else if (customAuth) {
    return authHeaderPair("custom", btoa(JSON.stringify(customAuth)));
  } else if (test) {
    return authHeaderPair("test", test.testAuthToken);
  } else if (appBuilderKey) {
    return authHeaderPair("app-builder", appBuilderKey);
  } else if (loginWithEmail) {
    return authHeaderPair("email", btoa(JSON.stringify(loginWithEmail)));
  }
  return {};
}

function getRequestHeaders(params) {
  return {
    ...extractAuthHeaders(params),
    "x-client-build-version": CODEBUILD_BUILD_NUMBER,
    "x-client-commit-id": CODEBUILD_GIT_SHORT_ID,
    "x-client-device-type": "web",
  };
}

export async function doAxiosGet(params) {
  return await doAxiosRequest("get", params);
}

export async function doAxiosPost(params) {
  return await doAxiosRequest("post", params);
}

export async function doAxiosDelete(params) {
  return await doAxiosRequest("delete", params);
}

export async function doAxiosRequest(method, params) {
  const {
    url,
    queryParams = {},
    data,
    cancelToken,
    signal,
  } = params;
  // log.debug("loading url", url, queryParams, data, params);
  try {
    const { data: result } = await axios({
      method,
      url,
      params: queryParams,
      data,
      headers: getRequestHeaders(params),
      cancelToken,
      signal,
      // timeout: 30000,
    });
    return result;
  } catch (error) {
    // https://github.com/axios/axios#handling-errors
    log.error(
      `doAxiosRequest() failed for ${method}:${url} ${JSON.stringify(
        queryParams
      )}`
    );
    if (error.response) {
      log.error(`Server responded with HTTP status: ${error.response.status}`);
      log.error(error.response.data);
      log.error(error.response.status);
      log.error(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      log.error("The request was made but no response was received.");
      log.error(error.request);
    } else {
      log.error("Something happened in setting up the request that triggered an Error");
      log.error("Error", error.message);
    }
    throw error;
  }
}

export async function getPageData(params) {
  const { pageDataPath } = params;
  const url = `/page-data/${pageDataPath}/page-data.json`;
  return doAxiosGet({ url, ...params });
}

export async function getECSBuildTasks(params) {
  return doAxiosGet({ url: `${API_ENDPOINT}/build/tasks`, ...params });
}

export async function getMobileAppBuilds(params) {
  return doAxiosGet({ url: `${API_ENDPOINT}/mobile/builds`, ...params });
}

export async function getNews(params) {
  return await doAxiosGet({ url: `${API_ENDPOINT}/news`, ...params });
}

export async function userAuthSetup(params) {
  return await doAxiosPost({
    url: `${API_ENDPOINT}/user/auth/setup`,
    ...params,
  });
}

export async function newLeadForm(params) {
  return await doAxiosPost({
    url: `${API_ENDPOINT}/intake/lead`,
    ...params,
  });
}

export async function postNews(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/news`, ...params });
}

export async function getEarnings(params) {
  return await doAxiosGet({ url: `${API_ENDPOINT}/earnings`, ...params });
}

export async function newEarnings(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/earnings`, ...params });
}

export async function deleteEarnings(params) {
  return await doAxiosDelete({ url: `${API_ENDPOINT}/earnings`, ...params });
}

export async function getNotifications(params) {
  return await doAxiosGet({ url: `${API_ENDPOINT}/notifications`, ...params });
}

export async function saveNotification(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/notifications`, ...params });
}

export async function getBlog(params) {
  return await doAxiosGet({ url: `${API_ENDPOINT}/blog`, ...params });
}

export async function saveBlog(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/blog`, ...params });
}

export async function deleteBlog(params) {
  return await doAxiosDelete({ url: `${API_ENDPOINT}/blog`, ...params });
}

export async function recordEarnings(params) {
  return await doAxiosPost({
    url: `${API_ENDPOINT}/earnings/record`,
    ...params,
  });
}

export async function symbolsAdmin(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/symbols/admin`, ...params });
}

export async function buildWebApp(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/build/app`, ...params });
}

export async function chatApi(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/chat`, ...params });
};

export async function getChatHistory(params) {
  return await doAxiosGet({ url: `${API_ENDPOINT}/chat/history`, ...params });
};

export async function deleteChatConversation(params) {
  return await doAxiosDelete({ url: `${API_ENDPOINT}/chat/history`, ...params });
};

export async function getChatConfig(params) {
  return await doAxiosGet({ url: `${API_ENDPOINT}/chat/config`, ...params });
};

export async function saveChatConfig(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/chat/config`, ...params });
};

export async function paymentApi(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/payment`, ...params });
};

export async function transcribeEarnings(params) {
  return await doAxiosPost({
    url: `${API_ENDPOINT}/earnings/transcribe`,
    ...params,
  });
}

export async function notifyUsersEventPublished(params) {
  return await doAxiosPost({
    url: `${API_ENDPOINT}/earnings/notify`,
    ...params,
  });
}

export async function subscribeEmail(params) {
  return await doAxiosPost({
    url: `${API_ENDPOINT}/subscribe/email`,
    ...params,
  });
}

export async function getSubscription(params) {
  const { queryParams } = params || {};
  const combinedQueryParams = {
    query_type: `subscription`,
    ...queryParams,
  };
  return await doAxiosGet({ url: `${API_ENDPOINT}/web/subscription`, ...params, queryParams: combinedQueryParams });
};

export async function deleteSubscription(params) {
  return await doAxiosDelete({ url: `${API_ENDPOINT}/web/subscription`, ...params });
};

export async function getInvoices(params) {
  const { queryParams } = params || {};
  const combinedQueryParams = {
    query_type: `invoice`,
    ...queryParams,
  };
  return await doAxiosGet({ url: `${API_ENDPOINT}/web/subscription`, ...params, queryParams: combinedQueryParams });
};

export async function getApiKey(params) {
  return await doAxiosGet({ url: `${API_ENDPOINT}/api/keys`, ...params });
}

export async function generateApiKey(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/api/keys`, ...params });
}

export async function deleteApiKey(params) {
  return await doAxiosDelete({ url: `${API_ENDPOINT}/api/keys`, ...params });
}

export async function getProfile(params) {
  return await doAxiosGet({ url: `${API_ENDPOINT}/profile`, ...params });
}

export async function saveProfile(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/profile`, ...params });
}

export async function deleteProfile(params) {
  return await doAxiosDelete({ url: `${API_ENDPOINT}/profile`, ...params });
}

export async function fetchCompany(exhcange, symbol) {
  const url = `${API_ENDPOINT}/company?exchange=${exhcange}&symbol=${symbol}`;
  return await doGet(url);
}

export async function getCompany(params) {
  const suffix = LOCAL_ONLY_DEVELOPMENT ? ".json" : "";
  return await doAxiosGet({ url: `${API_ENDPOINT}/company${suffix}`, ...params });
}

export async function updateCompany(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/company`, ...params });
}

export async function getSiteStats(appBuilderKey, params) {
  // console.log("getSiteStats:", params);
  const suffix = LOCAL_ONLY_DEVELOPMENT ? ".json" : "";
  let res = await doAxiosGet({
    url: `${API_GATEWAY_ENDPOINT}/site/stats${suffix}`,
    context: {
      sessionContext: {
        appBuilderKey,
      },
    },
    ...params,
  });
  // console.log("Site stats:", res);
  if (!res) {
    console.error(
      "The backend API returned null siteStats!  This is unexpected."
    );
    return {
      earnings_events_recorded: 1,
      companies: 1,
      events_by_day: [],
      published_events_by_day: [],
    };
  }
  return res;
}

export async function getSP500Companies(params) {
  return await doAxiosGet({ url: `${API_ENDPOINT}/companies/sp500`, ...params });
};

export const earningsQuery = async (queryParams, appBuilderKey) => {
  const suffix = LOCAL_ONLY_DEVELOPMENT ? ".json" : "";
  const results = await getEarnings({
    url: `${API_GATEWAY_ENDPOINT}/earnings${suffix}`,
    queryParams: {
      params: JSON.stringify(queryParams),
    },
    context: {
      sessionContext: {
        appBuilderKey,
      },
    },
  });
  // console.log("earningsResults:", results);
  return results.earnings.map(
    ({ quarter, year, exchange, symbol, primary_conference_date }) => {
      return {
        exchange,
        quarter: quarter.toUpperCase(),
        year,
        symbol,
        conference_date: primary_conference_date,
      };
    }
  );
};

const earningsWithCompanyName = async (queryParams, appBuilderKey) => {
  const result = await earningsQuery(queryParams, appBuilderKey);
  // console.log("earningsWithCompanyName queryParams: ", JSON.stringify(queryParams));
  // console.log("earningsWithCompanyName result:", result);
  const symbolsConfig = {
    endpoint: CLOUD_FRONT_ABSOLUTE_ENDPOINT,
    symbolsFile: "all-symbols.json",  // Problem: all-symbols.json does not contain company name overrides
    rawDataFormat: "json",
  };
  // Ensure all values are non-null, truthy
  const filteredResult = result.filter(
    ({ quarter, year, exchange, symbol, conference_date }) =>
      !!quarter && !!year && !!exchange && !!symbol && !!conference_date
  );
  const allResults = await Promise.all(
    filteredResult.map(async ({ quarter, year, exchange, symbol, conference_date }) => {
      // console.log("Exchange:", exchange, "Symbol:", symbol);
      // TODO: First lookup _sym in symbols-v2, only if not found look up in all symbols
      let _sym = await getSymbol(exchange, symbol, symbolsConfig);
      // console.log("_sym", _sym);
      const { name = null } = _sym || {};
      if (!name) {
        log.warn(`Could not find company name for: ${exchange}_${symbol}`);
      }
      return {
        quarter,
        year,
        exchange,
        symbol,
        companyName: name,
        conference_date,
      };
    })
  );
  return allResults.filter(
    ({ quarter, year, exchange, symbol, companyName, conference_date }) =>
      quarter && year && exchange && symbol && companyName && conference_date
  ); // Ensure all values are non-null, truthy
};

export const getRecentCalls = async (appBuilderKey) => {
  const queryParams = {
    sort_by: "recording_stop_date",
    fields_present: [
      "audio_file",
      "exchange",
      "symbol",
      "primary_conference_date",
      "year",
      "quarter",
    ],
    fields_not_present: [],
    match: {
      is_published: true,
      primary: true,
    },
    size: 5,
  };
  log.debug("getRecentCalls()", queryParams);
  return await earningsWithCompanyName(queryParams, appBuilderKey);
};

export const earningsCallsQueryParams = () => {
  return {
    sort_by: "primary_conference_date",
    sort_order: "asc",
    fields_present: [
      "exchange",
      "symbol",
      "primary_conference_date",
      "year",
      "quarter",
    ],
    fields_not_present: [],
    match: {
      primary: true,
    },
    not_match: {
      news_wire: "test",
    },
    filters: [hasConferenceFilter()],
    size: 1000,
  };
};

const hasConferenceFilter = () => {
  return {
    bool: {
      should: [
        {
          match_phrase: {
            has_teleconference: true,
          },
        },
        {
          match_phrase: {
            has_web_cast: true,
          },
        },
      ],
      minimum_should_match: 1,
    },
  };
};

export const getUpcomingCalls = async (appBuilderKey) => {
  log.debug("getUpcomingCalls()");
  const dateRangeFilter = {
    primary_conference_date: {
      format: "strict_date_optional_time",
      gte: new Date().toISOString(),
    },
  };
  const queryParams = {
    ...earningsCallsQueryParams(),
    range_filter: [dateRangeFilter],
  };
  return await earningsWithCompanyName(queryParams, appBuilderKey);
};

export const getEarningsCalendar = async ({
  startingDate = new Date(),
  daysBack = 30,
  daysForward = 30,
  appBuilderKey,
}) => {
  // console.log("earningsCalendar()");
  const dateRangeFilter = {
    primary_conference_date: {
      format: "strict_date_optional_time",
      gte: startOfDay(subDays(startingDate, daysBack)).toISOString(),
      lte: endOfDay(addDays(startingDate, daysForward)).toISOString(),
    },
  };
  const queryParams = {
    ...earningsCallsQueryParams(),
    range_filter: [dateRangeFilter],
    size: 2500,
  };
  return await earningsWithCompanyName(queryParams, appBuilderKey);
};

export async function identifyDevice(params) {
  return doAxiosGet({ url: `${API_ENDPOINT}/id`, ...params });
};

export async function newSession(params) {
  return doAxiosPost({ url: `${API_ENDPOINT}/login`, data: {}, ...params });
};

export async function destroySession(params) {
  return doAxiosPost({ url: `${API_ENDPOINT}/logout`, data: {}, ...params });
};

export async function getTranscript(transcription_file, context) {
  // console.log(" -> getTranscript:", transcription_file);
  // console.log(`${API_GATEWAY_ENDPOINT}/transcript`);
  return await doAxiosGet({
    url: `${API_GATEWAY_ENDPOINT}/transcript`,
    queryParams: {
      transcription_file,
    },
    context,
  });
};

export async function recordAnalyticsEvents(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/tk`, ...params });
};

export async function pageLoadEvent(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/pg`, ...params });
};

export async function fileUpload(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/file/upload`, ...params });
};

export async function brockTestApi(params) {
  return await doAxiosPost({ url: `${API_ENDPOINT}/brockTest`, ...params });
};
