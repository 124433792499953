import React, { useEffect } from 'react';
import { CODEBUILD_BUILD_NUMBER, LOG_ROCKET_APP_ID } from "api-config";
import { isBrowser } from './Browser';
import LogRocket from 'logrocket';
import { useSessionContext } from "./Session";
import log from 'loglevel';


const LogRocketWrapper = () => {
  const { sessionContext } = useSessionContext();
  log.debug(`LogRocketWrapper: sessionContext`, sessionContext);
  const { isAdminUser, session } = sessionContext || {};
  const { user } = session || {};
  log.debug(`LogRocketWrapper: user`, user);
  const { email, name } = user || {};

  useEffect(() => {
    if (isBrowser()) {
      log.info(`LogRocket::init() ${LOG_ROCKET_APP_ID} ${CODEBUILD_BUILD_NUMBER}`);
      LogRocket.init(LOG_ROCKET_APP_ID, {
        release: CODEBUILD_BUILD_NUMBER,
        serverURL: `${window.location.origin}/i`,
      });
    } else {
      log.info("not initializing LogRocket due to no browser detected.");
    }
  }, []);

  useEffect(() => {
    if (isBrowser()) {
      const traits = {
        email,
        name,
        isAdminUser,
      };
      log.info(`LogRocket.identify(${email}) traits:`, traits);
      LogRocket.identify(email, traits);
    } else {
      log.info("not calling LogRocket.identify due to no browser detected.");
    }
  }, [email, name, isAdminUser]);

  return (<></>);
};

export {
  LogRocketWrapper
};
