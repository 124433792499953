import log from 'loglevel';

let testAuthAdminToken = "";
let testAuthBlogUserToken = "";
let testAuthUserToken = "";
const API_GATEWAY_STACK_ALPHA = "k4pe12992f";
const API_GATEWAY_STACK_PROD = "1kfhj30r1f";
const _INTERNAL_TEST_ADMIN_TOKEN = "06579D8B-6591-490C-835B-EEC73719CA25";
const _INTERNAL_TEST_BLOG_USER_TOKEN = "14352A0B-AD43-4E79-A8DB-20A66227F73B";
const _INTERNAL_TEST_USER_TOKEN = "A7498453-FCBE-45AA-848D-B9C80EAFE024";
const CLOUDFRONT_ALPHA_ID = "d21q3hefg11w4k";
const CLOUDFRONT_PROD_ID = "d13s95w40fp4px";
const API_PATH_PREFIX = `a2`;
let apiStageId = "";
let symbolsFile = "";
let cloudFrontId = "";
let apiGatewayEndpoint = "";
let cloudFrontEndpoint = "";
let baseDomainName = "";
let kibanaEndpoint = "";
let dataFilesBucket = "";
let smallCalendarDaysBack;
let smallCalendarDaysForward;
let logRocketAppId;
let maxCompanyApiConcurrency;
let stripePublishableKey;
let stripePublishableTestKey;
let stripeBillingUrl;

export const CFN_STAGE = process.env.GATSBY_CFN_STAGE || "alpha";
console.log("This is a console log statement");
log.debug("This is a debug statement");
log.debug("CFN_STAGE", CFN_STAGE);
export const IS_ALPHA_STAGE = CFN_STAGE === "alpha";
export const IS_PROD_STAGE = CFN_STAGE === "prod";
const USE_DEV_SYMBOLS = process.env.GATSBY_USE_DEV_SYMBOLS === "true";
const DEV_SYMBOLS_FILE_NAME =  "dev-symbols.txt";
export const GITHUB_PULL_REQUEST_URL = process.env.GATSBY_GITHUB_PULL_REQUEST_URL;

log.debug("IS_ALPHA_STAGE", IS_ALPHA_STAGE);
log.debug("IS_PROD_STAGE", IS_PROD_STAGE);
log.debug("IS_ALPHA_STAGE", IS_ALPHA_STAGE);

/*
console.log("IN API CONFIG: Listing all environment variables...");
for (let [key, value] of Object.entries(process.env)) {
  console.log(` --> ${key}=${value}`);
}
console.log("DONE!");
*/

console.log(`\n\n-------> Stage = ${CFN_STAGE}`);
if (IS_ALPHA_STAGE) {
  apiStageId = API_GATEWAY_STACK_ALPHA;
  cloudFrontId = CLOUDFRONT_ALPHA_ID;
  baseDomainName = `alpha.earningscall.biz`;
  dataFilesBucket = `alpha-ecb-bucketsnestedst-datafilesbucket2dcb3fd1-1i1r8gq9opyqm`;
  stripePublishableKey = `pk_live_51P18YVJUOK2XBMgm0maekgS7JOswNTfAc1nQnzvVRjFQr02n7Jg7QVPtRSemWK3tP6oylLTZp8pIB27EfU5bDqQJ00N8qVfNXI`;
  stripePublishableTestKey = `pk_test_51P18YVJUOK2XBMgmPcOfazIstLWt7CR7E2w4nq3Q8jSNmisdqrK0FFTrWqOfivxATm6XLID9aCPBX6hzSjU78vrp00ngJfM0ql`;
  stripeBillingUrl = `https://billing.stripe.com/p/login/test_28o00n8hT4ptf72144`;
} else if (IS_PROD_STAGE) {
  apiStageId = API_GATEWAY_STACK_PROD;
  cloudFrontId = CLOUDFRONT_PROD_ID;
  baseDomainName = `earningscall.biz`;
  dataFilesBucket = `prod-ecb-bucketsnestedsta-datafilesbucket2dcb3fd1-1fzfk71u0tjyu`;
  stripePublishableKey = `pk_live_51OxyR5DXWZwdbNFA3XBu3L0w3BeT96Dp23nHIWnmibgT6x7ozkwekJ4FGhuYd2jeVasoK3pJYTg45U16Wuo7N8Wc007JnWZBm8`;
  stripePublishableTestKey = ``;
  stripeBillingUrl = `https://billing.stripe.com/p/login/dR65mdcFE85o3n2bII`;
}
cloudFrontEndpoint = `https://${baseDomainName}`;
kibanaEndpoint = `https://kibana.${baseDomainName}`;

if (IS_ALPHA_STAGE) {
  logRocketAppId = "vquhxv/cca-app";
  maxCompanyApiConcurrency = 1;
} else if (IS_PROD_STAGE) {
  logRocketAppId = "b5idae/earningscall";
  maxCompanyApiConcurrency = 2;
}

const LOCAL_FILE_SERVER_ENDPOINT = "http://localhost:8000";

// Update this variable to true if you're on an airplane, and have no internet access.
// NOTE: You'll need a local file server, for example: $ python -m http.server 8000
export const LOCAL_ONLY_DEVELOPMENT = false;

if (LOCAL_ONLY_DEVELOPMENT) {
  apiGatewayEndpoint = `${LOCAL_FILE_SERVER_ENDPOINT}/${API_PATH_PREFIX}`;
} else {
  apiGatewayEndpoint = `https://${apiStageId}.execute-api.us-east-1.amazonaws.com/${API_PATH_PREFIX}`;
}

if (process.env.GATSBY_MAX_COMPANY_API_CONCURRENCY) {
  maxCompanyApiConcurrency = parseInt(process.env.GATSBY_MAX_COMPANY_API_CONCURRENCY);
}

const nodeEnv = process.env.NODE_ENV;

if (nodeEnv === "development") {
  // Development mode (only executed in )
  smallCalendarDaysBack = 10;
  smallCalendarDaysForward = 10;
  symbolsFile = DEV_SYMBOLS_FILE_NAME;
  testAuthAdminToken = _INTERNAL_TEST_ADMIN_TOKEN;
  testAuthBlogUserToken = _INTERNAL_TEST_BLOG_USER_TOKEN;
  testAuthUserToken = _INTERNAL_TEST_USER_TOKEN;
  const logLevel = "trace";
  console.log(`Setting log level to ${logLevel}.`);
  log.setLevel(logLevel);
} else {
  smallCalendarDaysBack = 5;
  smallCalendarDaysForward = 33;
  symbolsFile = "symbols.txt";
  if (IS_ALPHA_STAGE) {
    log.setLevel("debug");
  } else if (IS_PROD_STAGE) {
    log.setLevel("info");
  }
}

if (USE_DEV_SYMBOLS) {
  symbolsFile = DEV_SYMBOLS_FILE_NAME;
}

export const LOG_ROCKET_APP_ID = logRocketAppId;
export const CODEBUILD_BUILD_NUMBER = process.env.GATSBY_CODEBUILD_BUILD_NUMBER || "dev";
export const CODEBUILD_RESOLVED_SOURCE_VERSION = process.env.GATSBY_CODEBUILD_RESOLVED_SOURCE_VERSION || "dev";
export const CODEBUILD_GIT_SHORT_ID = CODEBUILD_RESOLVED_SOURCE_VERSION.substring(0, 8);
export const BUILD_TIME = new Date().toISOString();
export const NODE_ENV = process.env.NODE_ENV;
export const TEST_AUTH_ADMIN_TOKEN = testAuthAdminToken; // Will be "" for non-development builds
export const TEST_AUTH_BLOG_USER_TOKEN = testAuthBlogUserToken; // Will be "" for non-development builds
export const TEST_AUTH_USER_TOKEN = testAuthUserToken; // Will be "" for non-development builds
export const API_GATEWAY_ENDPOINT = apiGatewayEndpoint;
export const CLOUD_FRONT_ABSOLUTE_ENDPOINT = LOCAL_ONLY_DEVELOPMENT ? LOCAL_FILE_SERVER_ENDPOINT : cloudFrontEndpoint;
export const KIBANA_ENDPOINT = kibanaEndpoint;
export const SYMBOLS_FILE = symbolsFile;
export const SYMBOLS_FILE_ABSOLUTE_PATH = `${CLOUD_FRONT_ABSOLUTE_ENDPOINT}/${SYMBOLS_FILE}`;
export const SITE_ROOT = LOCAL_ONLY_DEVELOPMENT ? "" : CLOUD_FRONT_ABSOLUTE_ENDPOINT;
export const API_ENDPOINT = `${SITE_ROOT}/${API_PATH_PREFIX}`;
export const LOG_ROCKET_PROXY_URL = `${SITE_ROOT}`;
export const STATIC_FILES_ENDPOINT = LOCAL_ONLY_DEVELOPMENT ? LOCAL_FILE_SERVER_ENDPOINT : SITE_ROOT;
export const AUDIO_FILE_ROOT = `${STATIC_FILES_ENDPOINT}/f`;
export const TRANSCRIPT_FILE_ROOT = `${STATIC_FILES_ENDPOINT}/t`;
export const DATA_FILES_BUCKET = dataFilesBucket;
export const SMALL_CALENDAR_DAYS_BACK = smallCalendarDaysBack;
export const SMALL_CALENDAR_DAYS_FORWARD = smallCalendarDaysForward;
export const MAX_COMPANY_API_CONCURRENCY = maxCompanyApiConcurrency;
export const STRIPE_ALPHA_TEST_MODE_ENABLED = true;
export const STRIPE_TEST_MODE_ENABLED = IS_ALPHA_STAGE ? STRIPE_ALPHA_TEST_MODE_ENABLED : false;
export const STRIPE_PUBLISHABLE_KEY = STRIPE_TEST_MODE_ENABLED ? stripePublishableTestKey : stripePublishableKey;
export const IS_DEVELOPMENT = process.env.GATSBY_IS_DEVELOP === "true";
export const ENABLE_LOG_ROCKET_IN_DEVELOPMENT = false;
export const WEB_SOCKET_URL = `wss://${baseDomainName}/websocket`;
export const PUBLIC_API_BASE_URL = `https://v2.api.${baseDomainName}`;
export const STRIPE_BILLING_URL = stripeBillingUrl;

log.debug("CODEBUILD_BUILD_NUMBER:", CODEBUILD_BUILD_NUMBER);
log.debug("CODEBUILD_GIT_SHORT_ID:", CODEBUILD_GIT_SHORT_ID);
log.debug("BUILD_TIME:", BUILD_TIME);
log.debug("NODE_ENV:", NODE_ENV);
log.debug("CFN_STAGE:", CFN_STAGE);
log.debug("NODE_ENV:", NODE_ENV);
log.debug("TEST_AUTH_ADMIN_TOKEN:", TEST_AUTH_ADMIN_TOKEN);
log.debug("TEST_AUTH_BLOG_USER_TOKEN:", TEST_AUTH_BLOG_USER_TOKEN);
log.debug("TEST_AUTH_USER_TOKEN:", TEST_AUTH_USER_TOKEN);
log.debug("AUDIO_FILE_ROOT:", AUDIO_FILE_ROOT);
log.debug("API_ENDPOINT:", API_ENDPOINT);
log.debug("API_GATEWAY_ENDPOINT:", API_GATEWAY_ENDPOINT);
log.debug("SYMBOLS_FILE:", SYMBOLS_FILE);
log.debug("SYMBOLS_FILE_ABSOLUTE_PATH:", SYMBOLS_FILE_ABSOLUTE_PATH);
log.debug("CLOUD_FRONT_ABSOLUTE_ENDPOINT:", CLOUD_FRONT_ABSOLUTE_ENDPOINT);
log.debug("KIBANA_ENDPOINT:", KIBANA_ENDPOINT);
log.debug("MAX_API_CONCURRENCY:", MAX_COMPANY_API_CONCURRENCY);
log.debug("STRIPE_TEST_MODE_ENABLED:", STRIPE_TEST_MODE_ENABLED);
log.debug("STRIPE_PUBLISHABLE_KEY:", STRIPE_PUBLISHABLE_KEY);


export function audioFileUrl(fileName) {
  return `${AUDIO_FILE_ROOT}/${fileName}`;
}

export function transcriptFileUrl(fileName) {
  return `${TRANSCRIPT_FILE_ROOT}/${fileName}`;
}
